<footer>   
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-3 col-item">
                    <h3 class="title-h3">Suporte</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/contato" title="Contato">
                                Contato
                            </a> 
                        </li>
                        <li>
                            <a href="#" title="Perguntas Frequentes">
                                Perguntas Frequentes
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/politicas-de-cancelamento" title="Políticas de Cancelamento">
                                Políticas de Cancelamento 
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/politicas-de-privacidade" title="Políticas de Privacidade">
                                Políticas de Privacidade
                            </a> 
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-item">
                    <h3 class="title-h3">Minha Conta</h3>
                    <ul class="menu-list">
                        <li>
                            <a routerLink="/minha-conta/pedidos" title="Minhas Viagens">
                                Minhas Viagens
                            </a>    
                        </li>
                        <li>
                            <a routerLink="/minha-conta/perfil" title="Meu Perfil">
                                Meu Perfil
                            </a> 
                        </li>
                        <li>
                            <a routerLink="/carrinho" title="Meu Carrinho">
                                Meu Carrinho
                            </a>  
                        </li>
                    </ul>
                </div>
                <div class="col-md-3 col-item">
                    <modulo posicao="contato" template="footer"></modulo>
                </div>
                <div class="col-md-3 col-item">
                    <modulo posicao="rede-social" template="footer"></modulo> 
                </div>
            </div>
        </div>  
    </div>    
    <div class="cadastur">
        <div class="container">
            <div class="list-item">
                <div class="item">
                    <figure>
                        <img src="/images/cadastur/selo.svg" alt="Selo" width="110px" /> 
                    </figure>   
                </div>  
                <div class="item">
                    <figure>
                        <img src="/images/cadastur/cadastur.svg" alt="Cadastur" width="180px" /> 
                    </figure> 
                </div>  
            </div>
        </div>
    </div>
    <div class="bottom">
        <div class="container">
            <div class="copyright">
                <p>
                    CNPJ: 54.207.884/0001-89
                </p>
                <p>
                    <span>Copyright © 2024</span>
                    <span>-</span>
                    <a href="https://digitalmovement.com.br/" target="_blank" title="Digital Movement - Soluções em Tecnologia">
                        <strong>Digital Movement</strong>
                        <img src="/images/logos/logo-dm.svg" alt="Digital Movement" width="20px" height="20px" />
                    </a>
                </p>
            </div> 
        </div>  
    </div>    
</footer>           